import Img from 'gatsby-image';
import React from 'react';
import moment from 'moment';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import {
  Article,
  AuthorInfo,
  TextContent,
  AuthorAnchor,
  AuthorHandle,
  NestedArticle,
  ArticleHeader,
  PostedDetails,
  NestedContent,
  EngagementCount,
  EngagementCounter,
  AuthorDisplayName,
  AuthorProfileImage,
} from './TweetEmbedLookalike.StyledComponents';
import { TwitterLikeSvg, TwitterCommentSvg, TwitterRetweetSvg } from './TwitterIconSvg';

const EXT_LINK_PROPS = {
  rel: 'noopener noreferrer',
  role: 'link',
  target: '_blank',
  onClick: e => e.stopPropagation(),
};

const AUTHOR_ANCHOR_PROPS = handle => ({
  ...EXT_LINK_PROPS,
  rel: `author ${EXT_LINK_PROPS.rel}`,
  role: 'link',
  href: `https://twitter.com/${handle.replace('@', '')}`,
});

const RICH_TEXT_RENDERER = content =>
  documentToReactComponents(content, {
    renderNode: {
      [INLINES.HYPERLINK]: ({ data }, children) => (
        <a {...EXT_LINK_PROPS} href={data.uri}>
          {children}
        </a>
      ),
    },
    renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
  });

const TweetEmbedLookalike = ({
  url,
  countLikes,
  authorName,
  textContent,
  nestedImage,
  nestedTweet,
  authorHandle,
  twitterClient,
  countComments,
  countRetweets,
  postedDateTime,
  authorIsVerified,
  authorProfileImage,
}) => {
  const authorNameRef = React.useRef(null);
  const articleLinkRef = React.useRef(null);
  const textContentRef = React.useRef(null);
  const nestedTextContentRef = React.useRef(null);

  const tweetLinkProps = { ...EXT_LINK_PROPS, href: url };
  const authorLinkProps = AUTHOR_ANCHOR_PROPS(authorHandle);
  const engagementCounts = [
    {
      Icon: <TwitterLikeSvg />,
      value: countLikes,
      hoverStroke: 'twitterPink',
    },
    {
      Icon: <TwitterCommentSvg />,
      value: countComments,
      hoverFill: 'twitterBlue',
    },
    {
      Icon: <TwitterRetweetSvg />,
      value: countRetweets,
      hoverStroke: 'twitterGreen',
    },
  ];

  // Render text nodes using Twitter's emoji renderer, for consistency and to adhere to twitter brand guidelines
  React.useLayoutEffect(() => {
    const refsToParse = [authorNameRef, textContentRef, nestedTextContentRef];

    if (!refsToParse.every(ref => ref && ref.current)) return; // wait for refs to be initialised
    if (typeof window === 'undefined' || typeof document === 'undefined') return; // ssr

    function loadTwemojis(scriptUrl) {
      const script = document.createElement('script');

      script.src = 'https://twemoji.maxcdn.com/v/latest/twemoji.min.js';
      script.crossOrigin = 'anonymous';

      document.body.appendChild(script);

      return new Promise((res, rej) => {
        script.onload = function() {
          res();
        };
      });
    }

    loadTwemojis().then(() => {
      refsToParse.forEach(ref => window.twemoji.parse(ref.current));
    });
  }, [authorNameRef.current, nestedTextContentRef.current]);

  const formatTweetPostedDetails = () => {
    const clientString = twitterClient ? `• Twitter for ${twitterClient}` : '';
    const formatString = `h:mm A • MMM, D YYYY [${clientString}]`;

    const utcDate = moment.utc(postedDateTime).toDate();
    const localDate = moment(utcDate).local();

    return localDate.format(formatString);
  };

  return (
    <Article onClick={() => articleLinkRef.current.click()}>
      <a {...tweetLinkProps} ref={articleLinkRef} />

      <ArticleHeader>
        <AuthorInfo>
          <AuthorProfileImage size={48} {...authorLinkProps}>
            <Img alt={`${authorHandle} Twitter avatar`} fluid={authorProfileImage.fluid} />
          </AuthorProfileImage>
          <AuthorAnchor {...authorLinkProps}>
            <AuthorDisplayName ref={authorNameRef} isVerified={authorIsVerified}>
              {authorName}
            </AuthorDisplayName>
            <AuthorHandle>
              {authorHandle}
            </AuthorHandle>
          </AuthorAnchor>
        </AuthorInfo>
      </ArticleHeader>

      <TextContent ref={textContentRef} cite={url}>
        {RICH_TEXT_RENDERER(textContent.json)}
      </TextContent>
      {Boolean(nestedImage) ? (
        <NestedContent>
          <Img alt={nestedImage.description} fluid={nestedImage.fluid} />
        </NestedContent>
      ) : null}
      {Boolean(nestedTweet) ? (
        <NestedContent>
          <NestedTweetLookalike {...nestedTweet} textContentRef={nestedTextContentRef} />
        </NestedContent>
      ) : null}

      <footer>
        <PostedDetails datetime={postedDateTime}>
          <a {...tweetLinkProps}>{formatTweetPostedDetails()}</a>
        </PostedDetails>
        <EngagementCounter>
          {engagementCounts.map((engagementCount, index) => (
            <EngagementCount key={`engagement-${index}`} {...tweetLinkProps} {...engagementCount} />
          ))}
        </EngagementCounter>
      </footer>
    </Article>
  );
};

const NestedTweetLookalike = ({
  url,
  authorName,
  textContent,
  authorHandle,
  authorProfileImage,
  textContentRef,
}) => {
  const articleAnchorRef = React.useRef(null);

  const onArticleClick = e => {
    e.stopPropagation();
    articleAnchorRef.current.click();
  };

  return (
    <NestedArticle onClick={onArticleClick}>
      <a {...EXT_LINK_PROPS} ref={articleAnchorRef} href={url} />
      <ArticleHeader withBird={false}>
        <AuthorInfo>
          <AuthorProfileImage size={20} {...AUTHOR_ANCHOR_PROPS(authorHandle)}>
            <Img alt={`${authorHandle} Twitter avatar`} fluid={authorProfileImage.fluid} />
          </AuthorProfileImage>
          <AuthorAnchor {...AUTHOR_ANCHOR_PROPS(authorHandle)} css={{ display: 'flex' }}>
            <AuthorDisplayName>{authorName}</AuthorDisplayName>
            <AuthorHandle>{authorHandle}</AuthorHandle>
          </AuthorAnchor>
        </AuthorInfo>
      </ArticleHeader>
      <TextContent ref={textContentRef} cite={url} padding={0.5} fontSize={16} lineHeight={20}>
        {RICH_TEXT_RENDERER(textContent.json)}
      </TextContent>
    </NestedArticle>
  );
};

export { TweetEmbedLookalike };
