import React from 'react';
import styled from 'styled-components';

import {
  ctaAnchorBaseStyles,
  sectionStandardLayout,
  sectionStandardPadding,
} from '../LandingPage.StyledComponents';
import { Large } from '../../text';

export const TweetReelOverflow = styled.div`
  ${sectionStandardLayout};
  overflow: auto;
  max-width: 1376px;

  & > * {
    ${sectionStandardPadding};
  }
`;

export const TweetReel = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(16)};
  justify-content: space-between;

  & > * + * {
    margin-left: ${({ theme }) => theme.spacing(4)};
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: max-content;
  }
  @media ${({ theme }) => theme.breakpoints.xs} {
    & > * + * {
      margin-left: ${({ theme }) => theme.spacing(1)};
    }
  }
`;

export const ReelItem = styled.div`
  width: 381px;
  height: max-content;
  background: white;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  border-radius: ${({ theme }) => theme.spacing(1.5)};

  &:hover {
    transform: translateY(-2px);
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    width: 85vw;
  }
`;

export const Cta = styled.div.attrs(props => ({
  children: (
    <a href={props.href}>
      {props.children}
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.6665 28.3333L30.9999 20L22.6665 11.6667"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1984 20.0583L9.83313 20.0583"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </a>
  ),
}))`
  ${sectionStandardLayout};
  ${sectionStandardPadding};
  max-width: 1376px;
  margin-top: ${({ theme }) => theme.spacing(4)};

  a {
    ${ctaAnchorBaseStyles};
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    padding-left: 0;
  }
  @media ${({ theme }) => theme.breakpoints.xlMin} {
    margin-left: 0;
  } 
`;
